import React, { useState, useEffect } from "react"
// import { useQuery } from "@apollo/client"
import { graphql, navigate} from "gatsby"
import { useLocation} from "@reach/router";
// import gql from "graphql-tag"
import moment from "moment"
import { Helmet } from "react-helmet"
import "@src/scss/App.scss"
import Seo from "@components/seo"
import Layout from "@components/layout"
import Stack from "react-bootstrap/Stack"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import MapComponent from "@components/property/PropertyMap/Map"
import PlanBlock from "@components/planBLock/planblock"
// import BrandLogo from "../images/logo.svg"
import BannerRender from "@components/modules/Banner/propertyDetails"
import ButtonLink from "@components/ButtonLink"
import { onlyNumbers, uaenumberformat } from "@components/utils/CleanedHtml"
import { StarberryIcons } from "@components/icons/index"
import SocialShare from "@components/socialShare"
import PropertyPrice from "@components/property/price"
import PropertyRooms from "@components/property/rooms"
import PropertyFloorArea from "@components/property/floorArea"
import PropertyFeatures from "@components/property/features"
import PropertySpecification from "@components/property/specification"
import PropertyDescription from "@components/property/description"
import PropertyMortgage from "@components/property/mortgageCalculator/mortgageModule"
import { SwiperSlide } from "swiper/react"
import Carousel from "@components/carousel"
import ModuleSection from "@components/modules/moduleWrapper"
import BoxCta from "@components/modules/BoxCta"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform"
import SimilarProperties from "@components/property/SimilarProperties/SimilarProperties"
import SimilarOffplanProjects from "@components/property/SimilarProperties/SimilarOffplanProjects"
import PropertyCarouselButtons from "@components/property/propertyCarouselButtons"
import FsLightbox from "fslightbox-react"
import ModalPortal from "@components/modal/portal"
import ModuleTitle from "@components/modules/Title"
import SpecialOffer from "@components/property/SpecialOffer"
import NegoDetails from "@components/property/NegoDetails"
import Broucher from "@components/property/brochure"
import NegoDetailsOffice from "@components/modules/TeamContact"
import PropReviewsList from '@components/ReviewsComponent/PropReviewsList'
import TotalReviewsCount from '@components/ReviewsComponent/TotalReviewsCount'

import {
  contentItem,
  fadeInFromTop,
  fadeInFromBottom,
} from "@components/utils/animations"
import NoImage from "../images/no-image.png"
import { isMobileOnly } from "react-device-detect"
import { indexOf } from "lodash"

const sliderConfig = {
  slidesPerView: 1,
  pagination: false,
  navigation: true,
  loop: true,
}

const PropButtons = ({ phoneNumber, data }) => {
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const priceFormat =
    data?.price && !Number.isNaN(data?.price)
      ? new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
      }).format(data?.price)
      : ""
  const max_priceFormat =
    data?.max_price && !Number.isNaN(data?.max_price)
      ? new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
      }).format(data?.max_price)
      : ""
  var text = `Hello,%0AI would like to get more information about this property you posted on your website:%0A%0AReference: ${data?.extra?.referenceNumber
    }%0A${data?.building ? "Type: " + capitalize(data?.building) + "%0A" : ""
    }Price: ${data?.max_price
      ? "From " + priceFormat + "-" + max_priceFormat
      : priceFormat
    }%0A${data?.extra?.community_propertyfinder
      ? "Community: " + data?.extra?.community_propertyfinder + "%0A"
      : ""
    }${data?.extra?.sub_community_propertyfinder
      ? "Sub Community: " + data?.extra?.sub_community_propertyfinder + "%0A%0A"
      : ""
    }Property: Link: ${pageurl}`

  return (
    <Stack
      className="gap-12 gap-md-24 gap-xl-60 mt-24 mt-md-8 mt-lg-0 image-banner__btns"
      variants={contentItem}
    >
      <Stack direction="horizontal" className="gap-12 gap-md-24">
        {data.department === "international_projects" ||   //data?.department === "new_developments"
          data?.department === "new_development_projects" ? (
          <ButtonLink
            variant="secondary"
            data={data}
            func="OffPlanModal"
            // manualLink={"https://projects.dubaioffplandevelopments.com/register-interest"}
            className="ps-40 pe-40 text-nowrap"
          >
            {data.department === "international_projects"
              ? "Request the Investment Case"
              : "Register Your Interest"}
          </ButtonLink>
        ) : (
          <ButtonLink
            variant="secondary"
            data={data}
            func="EnquirePropertyModal"
            className="ps-40 pe-40 text-nowrap"
          >
            Enquire Now
          </ButtonLink>
        )}
        {phoneNumber && (
          <ButtonLink
            manualLink={`tel:${uaenumberformat(phoneNumber)}`}
            variant="secondary"
          >
            <StarberryIcons iconName="phoneIcon" />
          </ButtonLink>
        )}
        {phoneNumber && (
          <ButtonLink
            manualLink={`https://wa.me/${onlyNumbers(
              "97143994937"
            )}?&text=${text}`}
            variant="whatsapp"
          >
            <StarberryIcons iconName="whatsAppIcon" />
          </ButtonLink>
        )}
      </Stack>
      <Stack direction="horizontal" className={`gap-12 gap-md-24 ${(data?.department === "short_term") && "prop-details-review-share"} `}>
      <SocialShare
        shareLabel={data.department === "international_projects" ||
          data?.department === "new_development_projects" ? "Share this project" : "Share this Property"}
        hideShareIcon={true}
        className="text-white"
        type="details-share"
        pageurl={pageurl}
      />
       {data?.department === "short_term" && 
         <TotalReviewsCount propertyId={data?.id} department={data?.department} linkReviews={true} showStars={true} />
       }
      </Stack>
    </Stack>
  )
}
const AfterTitle = ({
  completion_status,
  bedroom,
  num_beds,
  bathroom,
  floorarea,
  area,
  completionDate,
  developer_name,
  department,
}) => {
  return (
    <>
      {area && (
        <div className="property-details-area">
          <StarberryIcons iconName="mapEmptyIcon" className="bi" />
          {area}
        </div>
      )}
      <Stack
        className="gap-16 gap-md-32 gap-lg-40 flex-md-row mt-md-16"
        variants={contentItem}
      >
        {developer_name && (
          <div className="property-details-area text-nowrap">
            <StarberryIcons iconName="developerIcon" className="bi" />
            {developer_name}
          </div>
        )}
        {completionDate && (
          <div className="property-details-area">
            <StarberryIcons iconName="calendarIconFull" className="bi" />
            {moment(completionDate).format("MMMM YYYY")}
          </div>
        )}
        {department === "new_developments" ||
          department === "new_development_projects" ? null : (
          <Stack direction="horizontal" className="gap-32 gap-lg-40">
            {bedroom || bathroom || num_beds ? (
              <PropertyRooms
                bedroom={bedroom}
                bathroom={bathroom}
                num_beds={num_beds}
                className="property-details-rooms"
              />
            ) : null}
            {floorarea && (
              <PropertyFloorArea
                floorarea={floorarea}
                className="property-details-floorArea"
              />
            )}
          </Stack>
        )}
      </Stack>
    </>
  )
}

const PropertyDetailsTemplate = ({data}) => {

  // const [loading, setLoading] = useState(true);
  const [lightboxController, setLightboxController] = useState(false)
  const openLightbox = () => {
    setLightboxController(!lightboxController)
  }

  let id = data.glstrapi.properties[0].id
  let property_details= data.glstrapi.properties
  var { pathname } = useLocation()
  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname
  // let error = ""

  // useEffect(() => {
  //   const timeout = setTimeout(() => setLoading(false), 1500)
  //   return () => clearTimeout(timeout)
  // }, [loading])
  
  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo(0, 0)
  }, [property_details])

  const priceConverter = (amt) => {

    if (!Number.isNaN(amt)) {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "AED",
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
      }).format(amt)
    }
    return "";
  }

  const numFormat = (val) => {
    if (val && val!="") {
      return val.toFixed(2);
    }
    return "";
  }

  // if (loading)
  //   return (
  //     <Layout loading={loading}>
  //       <Loader loading={loading} error={error} hideText={true} fullScreen />
  //     </Layout>
  //   )

  // else {

    // if (property_details?.length === 0 ) {
    //   return (       
    //   <Layout className="product-page">        
    //   <Router>
    //     <Switch>
    //       <Route component={NotFoundPage} data={property_details} status={404} />
    //     </Switch>
    //   </Router>
    // </Layout>
    //   )
    // }

    
    return (
      <Layout
        popularSearch={
          property_details &&
          (property_details[0]?.department === "new_developments" ||
            property_details[0]?.department === "new_development_projects")
            ? "Offplan_Sale_Layout"
            : (property_details[0]?.department === "commercial" &&
              property_details[0]?.search_type === "sales")
            ? "Commercial_Sales_Layout"
            : (property_details[0]?.department === "commercial" &&
              property_details[0]?.search_type === "lettings")
            ? "Commercial_Leasing_Layout"
            : (property_details[0]?.department === "residential" &&
              property_details[0]?.search_type === "sales")
            ? "Sell_Buy_Layout"
            : (property_details[0]?.department === "residential" &&
              property_details[0]?.search_type === "lettings")
            ? "Rent_Layout"
            : "Sell_Buy_Layout"
        }
        bodyClass="property-details"
      >
       
        {property_details && property_details.length > 0
          ? property_details.map(data => {
            //console.log("propdetails",data)
              // ggfx images
              let processedImages = JSON.stringify({})
              if (data?.imagetransforms?.images_Transforms) {
                processedImages = data?.imagetransforms?.images_Transforms
              }

              let developerLogos = []
              let QRCodes = []
              let BannerImages = []
              data.images.map(obj => {
                BannerImages.push(obj)
              })

              if (data.development_logo) {
                data.development_logo.map(obj => {
                  developerLogos.push(obj)
                })
              }
              if (data.developer_logo) {
                data.developer_logo.map(obj => {
                  developerLogos.push(obj)
                })
              }
              if (data.qrcode) {
                data.qrcode.map(obj => {
                  QRCodes.push(obj)
                })
              }
              const imgLightbox = [...BannerImages]
              const arrImgs = imgLightbox?.map(img => {
                return img.url
              })
              // ggfx images
              const capitalize = s => {
                if (typeof s !== "string") return ""
                return s.charAt(0).toUpperCase() + s.slice(1)
              }

              const capitalizeAll = data => {
                if (typeof data !== "string") return ""
                return data
                  .toLowerCase()
                  .split(" ")
                  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              }
              const pageurl =
                typeof window !== "undefined" ? window.location.href : ""
              const priceFormat =
                data?.price && !Number.isNaN(data?.price)
                  ? new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "AED",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 4,
                      minimumSignificantDigits: 1,
                      maximumSignificantDigits: 4,
                    }).format(data?.price)
                  : ""
              const max_priceFormat =
                data?.max_price && !Number.isNaN(data?.max_price)
                  ? new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "AED",
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 4,
                      minimumSignificantDigits: 1,
                      maximumSignificantDigits: 4,
                    }).format(data?.max_price)
                  : ""
              var text = `Hello,%0AI would like to get more information about this property you posted on your website:%0A%0AReference: ${
                data?.extra?.referenceNumber
              }%0A${
                data?.building
                  ? "Type: " + capitalize(data?.building) + "%0A"
                  : ""
              }Price: ${
                data?.max_price
                  ? "From " + priceFormat + "-" + max_priceFormat
                  : priceFormat
              }%0A${
                data?.extra?.community_propertyfinder
                  ? "Community: " +
                    data?.extra?.community_propertyfinder +
                    "%0A"
                  : ""
              }${
                data?.extra?.sub_community_propertyfinder
                  ? "Sub Community: " +
                    data?.extra?.sub_community_propertyfinder +
                    "%0A%0A"
                  : ""
              }Property Link: ${pageurl}`
              const {
                display_address,
                building,
                status,
                bedroom,
                price,
                department,
              } = data

              const propertyType = building?.replace(/-/g, " ")

              let initialText = ""
              if (department === "commercial") {
                initialText = "Commercial"
              } else if (
                department === "new_development_projects" ||
                department === "new_developments"
              ) {
                initialText = "Off plan"
              } else if (department === "short_term") {
                initialText = "Short term rental"
              } else {
                initialText = ""
              }

              let titleText = ""
              if (department === "residential") {
                titleText = `${
                  propertyType ? capitalizeAll(propertyType) : ""
                } ${status.toLowerCase()} ${
                  bedroom ? `with ${bedroom} bedrooms` : ""
                } ${
                  display_address
                    ? `in ${display_address.replace(/-/g, ",")}`
                    : ""
                } at AED ${price ? price : ""}`
              } else {
                titleText = `${initialText} ${
                  propertyType ? propertyType : ""
                } ${status.toLowerCase()} ${
                  bedroom ? `with ${bedroom} bedrooms` : ""
                } ${
                  display_address
                    ? `in ${display_address.replace(/-/g, ",")}`
                    : ""
                } at AED ${price ? price : ""}`
              }

              const description = `Know the details of ${initialText.toLowerCase()} ${
                propertyType ? propertyType : ""
              } ${status.toLowerCase()} ${
                bedroom ? `with ${bedroom} bedrooms` : ""
              } ${
                display_address
                  ? `in ${display_address.replace(/-/g, ",")}`
                  : ""
              } at AED ${
                price ? price : ""
              }. Book a viewing to get assistance in finding the right ${
                propertyType ? propertyType : ""
              } for you.`

              var seo_image_Url = data?.images?.length > 0 ? data?.images[0]?.url : null
              seo_image_Url = seo_image_Url?.replace("/i.", "/x.")
              var parts = seo_image_Url?.split("/")
              seo_image_Url = seo_image_Url?.replace(parts[parts.length - 1],"336x220/" + parts[parts.length - 1])

              return (
                <React.Fragment key={id}>
                  <Seo
                    title={titleText}
                    description={description}
                    image={seo_image_Url ? seo_image_Url : data?.images?.length > 0 ? data?.images[0]?.url : null}
                  />
                  <Helmet>
          
                              <script type="application/ld+json">{`{
                                   "@context": "https://schema.org",
                                   "@type": "Residence",
                                   "name": "${data?.description}",
                                   "description": "${description}",
                                   "url": "${currentUrl}",
                                   "address": {
                                     "@type": "PostalAddress",
                                     "addressLocality": "Dubai",
                                     "addressCountry": "UAE"
                                   },
                                   "image": "${data?.images[0]?.url}",
                                   "numberOfRooms": ${data?.bedroom},
                                   "amenityFeature": ${JSON.stringify(data?.accomadation_summary,null,2)},
                                   "offers": {
                                    "@type": "Offer",
                                    "priceCurrency": "AED",
                                    "price": ${data?.price},
                                    "availability": "http://schema.org/InStock"
                                    }      
                    }`}
                    </script>
            
            
            </Helmet>
                  <BannerRender
                    ItemTitle={data?.description}
                    Buttons={
                      <PropButtons
                        data={data}
                        phoneNumber={data?.negotiator?.phone}
                      />
                    }
                    price={
                      <PropertyPrice
                        department={data?.department}
                        max_price={data?.max_price}
                        price={data?.price}
                        price_qualifier={data?.price_qualifier}
                        search_type={data?.search_type}
                        className="property-details-price"
                        currency={data?.currency}
                        region={data?.region}
                      />
                    }
                    afterTitle={
                      <AfterTitle
                        area={data?.display_address}
                        bedroom={data?.bedroom}
                        bathroom={data?.bathroom}
                        num_beds={data?.num_beds}
                        floorarea={data?.extra?.floorarea_min}
                        completionDate={data?.extra?.completionDate}
                        developer_name={data?.developer_name}
                        department={data?.department}
                        completion_status={data?.extra?.completion_status}
                      />
                    }
                  />
                  {BannerImages.length > 0 ? (
                    <Carousel className="property-carousel" {...sliderConfig}>
                      {BannerImages?.map(imgItem => {
                        const image_url = imgItem?.url

                        // // GGFX
                        // let processedImages = JSON.stringify({})
                        // if (imgItem?.imagetransforms?.images_Transforms) {
                        //   processedImages =
                        //     imgItem?.imagetransforms?.images_Transforms
                        // }
                        // GGFX
                        return (
                          <SwiperSlide key={imgItem.id}>
                            <ImageTransform
                              imagesources={image_url}
                              renderer="srcSet"
                              imagename="property.images.detail"
                              attr={{
                                alt:
                                  data?.display_address + " -  Exclusive Links",
                              }}
                              imagetransformresult={processedImages}
                              id={id}
                              testparam={true}
                            />
                          </SwiperSlide>
                        )
                      })}
                      <PropertyCarouselButtons
                        data={data}
                        BannerImages={BannerImages}
                        openLightbox={openLightbox}
                      />
                    </Carousel>
                  ) : (
                    <div className="property-carousel">
                      <img
                        src={NoImage}
                        className="img-fluid property-no-image"
                        alt=""
                      />
                    </div>
                  )}
                  {data?.facts &&
                    data?.facts?.length > 0 &&
                    data?.facts[0] !== "feature" && (
                      <SpecialOffer
                        facts={data?.facts}
                        search_type={data?.search_type}
                        department={data?.department}
                      />
                    )}
                  <ModuleSection
                    sectionClass={`property-details-info section-white`}
                  >
                    <Row>
                      <Col xs={12} xl={3}>
                        <Stack
                          className={`gap-32 gap-sm-70 gap-lg-48 gap-xl-56 mb-32 mb-md-48 mb-xl-0 ${
                            data?.search_type !== "offplan"
                              ? "flex-sm-row flex-lg-column"
                              : ""
                          }`}
                          variants={fadeInFromBottom}
                        >
                          {developerLogos && developerLogos.length > 0 && (
                            <div className="development-logos">
                              {developerLogos.map((logo, k) => {
                                return (
                                  <div className="development-logo">
                                    <img
                                      src={logo.url}
                                      key={k}
                                      alt="Development logo"
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          )}
                          {data?.extra && (
                            <>
                              <PropertySpecification
                                title="Specification"
                                data={data?.extra}
                                bedroom={data?.bedroom}
                                num_beds={data?.num_beds}
                                department={data?.department}
                                building={data?.building}
                                completionDate={data?.extra?.completionDate}
                                developer_name={data?.developer_name}
                                price={data?.price}
                                max_price={data?.max_price}
                                currency={data?.currency}
                                owner_ship_term={data?.extra?.owner_ship_term}
                                completion_status={
                                  data?.extra?.completion_status
                                }
                                QRCodes={QRCodes}
                                region={data?.region}
                              />
                            </>
                          )}

                          {data?.search_type == "offplan" &&
                            data?.brochure &&
                            data?.brochure.length > 0 && (
                              <ButtonLink
                                manualLink={`#broucher-form`}
                                variant="primary"
                                label={`Request a Brochure`}
                              ></ButtonLink>
                            )}

                          {data.department === "international_projects" ? (
                            <div className="d-md-block d-none">
                              <NegoDetailsOffice
                                data={data}
                                department={"international_projects"}
                                className="mt-sm-65 mt-lg-0"
                                moduleData={{
                                  sectionSpacing: " ",
                                  removeContainer: true,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="d-md-block d-none">
                              <NegoDetails
                                data={data}
                                className={`${
                                  data?.search_type !== "offplan"
                                    ? "mt-sm-65 mt-lg-0 "
                                    : ""
                                }`}
                                moduleData={{
                                  sectionSpacing: " ",
                                  removeContainer: true,
                                }}
                              />
                            </div>
                          )}
                        </Stack>
                        <Stack
                          className="gap-32 gap-md-48 gap-xl-56 mt-40 mb-mt-48 mb-xl-0"
                          variants={fadeInFromBottom}
                        ></Stack>
                      </Col>
                      <Col xs={12} xl={{ span: 8, offset: 1 }}>
                        <Stack
                          className="gap-32 gap-md-32"
                          variants={fadeInFromTop}
                        >
                          {data?.accomadation_summary.length > 0 && (
                            <PropertyFeatures
                              title="Key amenities"
                              data={data?.accomadation_summary}
                            />
                          )}

                          {data?.long_description && (
                            <PropertyDescription
                              title="Description"
                              data={data.long_description}
                            />
                          )}
                          {data?.extra?.key_feature_for_website && (
                            <Stack
                              className={`gap-16 gap-md-32 property-details-features`}
                            >
                              <ModuleTitle
                                as="h5"
                                title="Key Features"
                                className="property-details-info-title"
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.extra?.key_feature_for_website,
                                }}
                              />
                            </Stack>
                          )}

                          {data?.extra?.facilities && (
                            <Stack
                              className={`gap-16 gap-md-32 property-details-features`}
                            >
                              <ModuleTitle
                                as="h5"
                                title={"Key Facilities"}
                                className="property-details-info-title"
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.extra?.facilities,
                                }}
                              />
                            </Stack>
                          )}
                          {data?.extra?.location_proximity && (
                            <Stack
                              className={`gap-16 gap-md-32 property-details-features`}
                            >
                              <ModuleTitle
                                as="h5"
                                title={"Location Proximity"}
                                className="property-details-info-title"
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.extra?.location_proximity,
                                }}
                              />
                            </Stack>
                          )}
                          {(data?.extra?.unit_type_1 ||
                            data?.extra?.built_up_area_unit_type_1 ||
                            data?.extra?.price_starting_from_unit_type_1) && (
                            <Stack
                              className={`gap-16 gap-md-32 property-details-features availabilityList`}
                            >
                              <ModuleTitle
                                as="h5"
                                title={"Sizes & Prices"}
                                className="property-details-info-title"
                              />
                              <table className="table-responsive">
                                <tr>
                                  <th></th>
                                  <th>Sq.ft. From</th>
                                  <th>Price From</th>
                                </tr>
                                {[1, 2, 3, 4, 5, 6].map(unit => {
                                  if (
                                    data?.extra[`unit_type_${unit}`] ||
                                    data?.extra[`unit_type_${unit}`] ||
                                    data?.extra[
                                      `price_starting_from_unit_type_1${unit}`
                                    ]
                                  ) {
                                    return (
                                      <tr>
                                        <td>
                                          {data?.extra[`unit_type_${unit}`]}
                                        </td>
                                        <td>
                                          {numFormat(data?.extra[`built_up_area_unit_type_${unit}`])}
                                        </td>
                                        <td>
                                          {priceConverter(
                                            data?.extra[`price_starting_from_unit_type_${unit}`]
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  }
                                })}
                              </table>
                            </Stack>
                          )}
                          <PropertyMortgage />
                          {data.department === "international_projects" ? (
                            <div className="d-md-none d-block">
                              <NegoDetailsOffice
                                data={data}
                                department={"international_projects"}
                                className="mt-sm-65 mt-lg-0"
                                moduleData={{
                                  sectionSpacing: " ",
                                  removeContainer: true,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="d-md-none d-block">
                              <NegoDetails
                                data={data}
                                className="mt-sm-65 mt-lg-0"
                                moduleData={{
                                  sectionSpacing: " ",
                                  removeContainer: true,
                                }}
                              />
                            </div>
                          )}
                        </Stack>
                      </Col>
                    </Row>
                  </ModuleSection>
                  {data?.department === "new_developments" ||
                  data.department === "international_projects" ||
                  data?.department === "new_development_projects" ? (
                    <PlanBlock data={data.extra} />
                  ) : null}
                  {data?.department === "short_term" && 
                   <div id="property-reviews-list" className="section property-reviews-slider"><PropReviewsList propertyId ={data?.id} /></div> 
                  }
                  {data?.latitude && data?.longitude && (
                    <div
                      className="property-details-landing-map"
                      id="property-details-map"
                      data-item="property-details-map"
                    >
                      <MapComponent
                        zoom={12}
                        property_details_map_data={data}
                      />
                    </div>
                  )}
                  {data?.brochure && data?.brochure.length > 0 && (
                    <Broucher brochure={data?.brochure[0].url} />
                  )}

                  {data?.images.length > 0 && (
                    <ModuleSection
                      sectionClass={`property-details-image-grid section-grey`}
                      sectionSpacing={`py-48 py-md-64 py-lg-80`}
                    >
                      <Stack
                        direction="horizontal"
                        className="align-items-center mb-16 mb-md-40"
                      >
                        <ModuleTitle
                          title="Image Gallery"
                          className="module__titleTwo mb-0"
                        />
                        <Button
                          variant="link-primary-underlined"
                          className="ms-auto d-none d-md-block"
                          onClick={() => openLightbox()}
                        >
                          View all Enlarged Images
                        </Button>
                      </Stack>
                      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-16 g-lg-40">
                        {isMobileOnly ? (
                          <>
                            <Carousel
                              className="property-gallery-carousel"
                              {...sliderConfig}
                            >
                              {data?.images?.map(imgItem => {
                                // GGFX
                                // let processedImages = JSON.stringify({})
                                // if (imgItem?.imagetransforms?.images_Transforms) {
                                //   processedImages =
                                //     imgItem?.imagetransforms?.images_Transforms
                                // }
                                // GGFX
                                return (
                                  <SwiperSlide key={imgItem.id}>
                                    <Button
                                      variant="none"
                                      className="property-img-gallery"
                                      onClick={() => openLightbox()}
                                    >
                                      <ImageTransform
                                        imagesources={imgItem?.url}
                                        renderer="srcSet"
                                        imagename="property.images.detailgallery"
                                        attr={{
                                          alt:
                                            data?.display_address +
                                            " -  Exclusive Links",
                                        }}
                                        imagetransformresult={processedImages}
                                        id={id}
                                        testparam={true}
                                      />
                                    </Button>
                                  </SwiperSlide>
                                )
                              })}
                            </Carousel>
                          </>
                        ) : (
                          <>
                            {data?.images?.slice(0, 6)?.map((imgItem, i) => {
                              return (
                                <>
                                  <Col key={imgItem.id}>
                                    <Button
                                      variant="none"
                                      className="property-img-gallery"
                                      onClick={() => openLightbox()}
                                    >
                                      <ImageTransform
                                        imagesources={imgItem?.url}
                                        renderer="srcSet"
                                        imagename="property.images.detailgallery"
                                        attr={{
                                          alt:
                                            data?.display_address +
                                            " -  Exclusive Links",
                                        }}
                                        imagetransformresult={processedImages}
                                        id={id}
                                        // testparam={true}
                                      />
                                    </Button>
                                  </Col>
                                  {imgItem &&
                                    data?.images &&
                                    data?.images?.length > 6 &&
                                    i === 5 && (
                                      <Col xs={12} md={12} xxl={12}>
                                        <Button
                                          variant="loadMore"
                                          // disabled={pageDetails && start + limit >= pageDetails?.totalCount}
                                          // onClick={() => refetch({ limit: setNext(next + postsPerPage) })}
                                          onClick={() => openLightbox()}
                                          className="w-100"
                                        >
                                          View All Gallery images
                                        </Button>
                                      </Col>
                                    )}
                                </>
                              )
                            })}
                          </>
                        )}
                      </Row>
                    </ModuleSection>
                  )}
                  <BoxCta
                    moduleData={{
                      Title:
                        "Start your <strong>property journey</strong> with us",
                      Text: "We are ready to support you with your property requirements.",
                      moduleClass:
                        data?.images.length > 0
                          ? "pb-48 pb-md-64 pb-lg-120"
                          : "padding-top-required pb-48 pb-md-64 pb-lg-120",
                      sectionSpacing: " ",
                    }}
                    Button={[
                      {
                        id: "1",
                        data: data,
                        label:
                          data?.department === "new_developments" ||
                          data?.department === "new_development_projects"
                            ? "Register Your Interest"
                            : data.department === "international_projects"
                            ? "Request the Investment Case"
                            : "Enquire Now",
                        manualLink: "#",
                        func:
                          data?.department === "new_developments" ||
                          data.department === "international_projects" ||
                          data?.department === "new_development_projects"
                            ? "OffPlanModal"
                            : "EnquirePropertyModal",
                      },
                      data?.negotiator?.phone
                        ? {
                            id: "2",
                            label: "Call Agent",
                            manualLink: `tel:${uaenumberformat(
                              data?.negotiator?.phone
                            )}`,
                          }
                        : null,
                      data?.negotiator?.phone
                        ? {
                            id: "3",
                            label: "WhatsApp Agent",
                            manualLink: `https://wa.me/${onlyNumbers(
                              "97143994937"
                            )}?&text=${text}`,
                            variant: "whatsapp",
                          }
                        : null,
                    ]}
                  />
                  {data?.department === "new_development_projects" ? (
                    <SimilarOffplanProjects
                      propertyData={data}
                      moduleClass="pb-48 pb-md-64 pb-lg-120"
                      sectionSpacing=" "
                    />
                  ) : (
                    <SimilarProperties
                      propertyData={data}
                      moduleClass="pb-48 pb-md-64 pb-lg-120"
                      sectionSpacing=" "
                      department={data?.department}
                    />
                  )}

                  {data?.images.length > 0 && (
                    <ModalPortal>
                      <FsLightbox
                        toggler={lightboxController}
                        sources={arrImgs}
                        type="image"
                        customAttributes={[
                          {
                            alt: "Property Details Image - Exclusive Links",
                          },
                        ]}
                      />
                    </ModalPortal>
                  )}
                </React.Fragment>
              )
            })
          : ""}
      </Layout>
    )
  }
// }

export default PropertyDetailsTemplate

export const query = graphql`
  query ($id: ID!) {
    glstrapi {
      properties(where: {id: $id}) {
        id
        slug
        crm_id
        status
        department
        search_type
        title
        display_address
        num_beds
        relation_ref
        developer_name
        area
        price
        max_price
        price_qualifier
        address
        currency
        bedroom
        bathroom
        building
        images
        imagetransforms
        developer_logo
        development_logo
        qrcode
        negotiator
        crm_negotiator_id
        description
        long_description
        latitude
        longitude
        accomadation_summary
        extra
        facts
        virtual_tour
        floorplan
        brochure
        region
      }
    }
  }
`
